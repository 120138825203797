const textVariables = {
  channelName: 'Bruna',
  phoneNumber: '0887800600',
  phoneNumberDisplay: '088 780 0600',
  facebookProfileUrl: 'https://www.facebook.com/Brunawinkels',
  twitterProfileUrl: 'https://twitter.com/Bruna_nl',
  whatsappUrl: '',
  kiyohUrl: 'https://www.kiyoh.com/reviews/1066078/bruna',
  linkedinUrl: '',
  instagramUrl: '',

  // Customer service urls
  customerServiceUrl: 'https://klantenservice.bruna.nl',
  csLinkToB2B: 'https://klantenservice.bruna.nl',
  csEmailAddress: '',
  csLinkToMembership: 'https://klantenservice.bruna.nl',
  csLinkToContact: 'https://klantenservice.bruna.nl/topic/uBhzBbmpjEH4EQXdj/article/qAPeDzv8KpJvW9CqK',
  csLinkToEbooks: 'https://klantenservice.bruna.nl/search/ebooks',
  csLinkToFaq: 'https://klantenservice.bruna.nl/topic/uBhzBbmpjEH4EQXdj',
};

module.exports = textVariables;
